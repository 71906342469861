define("discourse/plugins/discourse-templates/lib/textarea-manipulator", ["exports", "@ember/object", "@ember/application", "discourse/mixins/textarea-text-manipulation"], function (_exports, _object, _application, _textareaTextManipulation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TextareaManipulator extends _object.default.extend(_textareaTextManipulation.default) {
    constructor(owner, textarea) {
      super(...arguments);
      (0, _application.setOwner)(this, owner);
      this._textarea = textarea;
      this.element = this._textarea;
      this.ready = true;
      this.init();
    }
    addBlock(text) {
      this._addBlock(this.getSelected(), text);
    }
  }
  _exports.default = TextareaManipulator;
});