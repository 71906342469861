define("discourse/plugins/discourse-templates/discourse/connectors/editor-preview/d-templates", ["exports", "@ember/object", "discourse-common/lib/get-owner"], function (_exports, _object, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const SELECTOR_EDITOR_PREVIEW = "#reply-control .d-editor-preview-wrapper > .d-editor-preview";
  var _default = _exports.default = (_obj = {
    setupComponent(args, component) {
      component.setProperties({
        templatesVisible: false,
        model: (0, _getOwner.getOwner)(this).lookup("controller:composer").model
      });
      this.appEvents.on("discourse-templates:show", this, "show");
      this.appEvents.on("discourse-templates:hide", this, "hide");
    },
    teardownComponent() {
      this.appEvents.off("discourse-templates:show", this, "show");
      this.appEvents.off("discourse-templates:hide", this, "hide");
    },
    shouldRender(args, component) {
      return !component.site.mobileView;
    },
    show(_ref) {
      let {
        onInsertTemplate
      } = _ref;
      const elemEditorPreview = document.querySelector(SELECTOR_EDITOR_PREVIEW);
      if (elemEditorPreview) {
        elemEditorPreview.style.display = "none";
      }
      this.set("onInsertTemplate", onInsertTemplate);
      this.set("templatesVisible", true);
    },
    hide() {
      const elemEditorPreview = document.querySelector(SELECTOR_EDITOR_PREVIEW);
      if (elemEditorPreview) {
        elemEditorPreview.style.display = "";
      }
      this.set("templatesVisible", false);
    }
  }, (_applyDecoratedDescriptor(_obj, "show", [_object.action], Object.getOwnPropertyDescriptor(_obj, "show"), _obj), _applyDecoratedDescriptor(_obj, "hide", [_object.action], Object.getOwnPropertyDescriptor(_obj, "hide"), _obj)), _obj);
});