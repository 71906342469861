define("discourse/plugins/discourse-templates/discourse/components/d-templates/filterable-list", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/runloop", "@glimmer/component", "@glimmer/tracking", "discourse-common/utils/decorators", "discourse/lib/ajax", "discourse/lib/ajax-error", "select-kit/components/tag-drop", "@ember/template-factory"], function (_exports, _component, _object, _service, _runloop, _component2, _tracking, _decorators, _ajax, _ajaxError, _tagDrop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="templates-filterable-list" {{did-insert this.load}}>
  
    <ConditionalLoadingSpinner @condition={{this.loading}}>
      <div class="templates-filter-bar">
        {{#if this.siteSettings.tagging_enabled}}
          <DTemplates::TagDrop
            @availableTags={{this.availableTags}}
            @tagId={{this.selectedTag}}
            @onChangeSelectedTag={{this.changeSelectedTag}}
          />
        {{/if}}
        <TextField
          class="templates-filter"
          @value={{this.listFilter}}
          placeholder={{i18n "templates.filter_hint"}}
        />
      </div>
      <div class="templates-list">
        {{#each this.filteredReplies as |r|}}
          <DTemplates::Item
            @template={{r}}
            @model={{@model}}
            @onInsertTemplate={{this.insertTemplate}}
          />
        {{/each}}
      </div>
    </ConditionalLoadingSpinner>
  </div>
  */
  {
    "id": "MxdRx2Ru",
    "block": "[[[11,0],[24,0,\"templates-filterable-list\"],[4,[38,0],[[30,0,[\"load\"]]],null],[12],[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"templates-filter-bar\"],[12],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"tagging_enabled\"]],[[[1,\"        \"],[8,[39,3],null,[[\"@availableTags\",\"@tagId\",\"@onChangeSelectedTag\"],[[30,0,[\"availableTags\"]],[30,0,[\"selectedTag\"]],[30,0,[\"changeSelectedTag\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[39,4],[[24,0,\"templates-filter\"],[16,\"placeholder\",[28,[37,5],[\"templates.filter_hint\"],null]]],[[\"@value\"],[[30,0,[\"listFilter\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"templates-list\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"filteredReplies\"]]],null]],null],null,[[[1,\"        \"],[8,[39,8],null,[[\"@template\",\"@model\",\"@onInsertTemplate\"],[[30,1],[30,2],[30,0,[\"insertTemplate\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"r\",\"@model\"],false,[\"did-insert\",\"conditional-loading-spinner\",\"if\",\"d-templates/tag-drop\",\"text-field\",\"i18n\",\"each\",\"-track-array\",\"d-templates/item\"]]",
    "moduleName": "discourse/plugins/discourse-templates/discourse/components/d-templates/filterable-list.hbs",
    "isStrictMode": false
  });
  let DTemplatesFilterableList = _exports.default = (_dec = (0, _object.computed)("replies", "selectedTag", "listFilter"), (_class = class DTemplatesFilterableList extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "loading", _descriptor2, this);
      _initializerDefineProperty(this, "listFilter", _descriptor3, this);
      _initializerDefineProperty(this, "replies", _descriptor4, this);
      _initializerDefineProperty(this, "selectedTag", _descriptor5, this);
      _initializerDefineProperty(this, "availableTags", _descriptor6, this);
    }
    get filteredReplies() {
      const filterTitle = this.listFilter.toLowerCase();
      return this.replies.map(template => {
        /* Give a relevant score to each template. */
        template.score = 0;
        if (template.title.toLowerCase().includes(filterTitle)) {
          template.score += 2;
        } else if (template.content.toLowerCase().includes(filterTitle)) {
          template.score += 1;
        }
        return template;
      })
      // Filter irrelevant replies.
      .filter(template => template.score !== 0)
      // Filter only replies tagged with the selected tag.
      .filter(template => {
        if (this.selectedTag === _tagDrop.ALL_TAGS_ID) {
          return true;
        }
        if (this.selectedTag === _tagDrop.NO_TAG_ID && template.tags.length === 0) {
          return true;
        }
        return template.tags.includes(this.selectedTag);
      }).sort((a, b) => {
        /* Sort replies by relevance and title. */
        if (a.score !== b.score) {
          return a.score > b.score ? -1 : 1; /* descending */
        } else if (a.title !== b.title) {
          return a.title < b.title ? -1 : 1; /* ascending */
        }

        return 0;
      });
    }
    async load() {
      try {
        this.loading = true;
        const results = await (0, _ajax.ajax)("/discourse_templates");
        this.replies = results.templates;
        if (this.siteSettings.tagging_enabled) {
          this.availableTags = Object.values(this.replies.reduce((availableTags, template) => {
            template.tags.forEach(tag => {
              if (availableTags[tag]) {
                availableTags[tag].count += 1;
              } else {
                availableTags[tag] = {
                  id: tag,
                  name: tag,
                  count: 1
                };
              }
            });
            return availableTags;
          }, {}));
        }
      } catch (e) {
        this.loading = false;
        (0, _ajaxError.popupAjaxError)(e);
      } finally {
        this.loading = false;
        (0, _runloop.schedule)("afterRender", () => document.querySelector(".templates-filter")?.focus());
      }
    }
    changeSelectedTag(tagId) {
      this.selectedTag = tagId;
    }
    insertTemplate(template) {
      this.args.onBeforeInsertTemplate?.();
      this.args.onInsertTemplate?.(template);
      this.args.onAfterInsertTemplate?.();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "listFilter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "replies", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedTag", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _tagDrop.ALL_TAGS_ID;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "availableTags", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "filteredReplies", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "filteredReplies"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "load", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeSelectedTag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeSelectedTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertTemplate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "insertTemplate"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DTemplatesFilterableList);
});