define("discourse/plugins/discourse-templates/discourse/components/d-templates/item", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _object, _component2, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <details class="template-item" id="template-item-{{@template.id}}">
    <summary class="template-item-title">
      <div class="template-item-title-text">{{@template.title}}</div>
  
      <div class="actions">
        <DButton
          class="templates-apply"
          @action={{action "apply"}}
          @icon="far-clipboard"
        />
      </div>
    </summary>
  
    <div class="templates-content">
      <CookText @rawText={{@template.content}} />
    </div>
  </details>
  */
  {
    "id": "mzCrPOh5",
    "block": "[[[10,\"details\"],[14,0,\"template-item\"],[15,1,[29,[\"template-item-\",[30,1,[\"id\"]]]]],[12],[1,\"\\n  \"],[10,\"summary\"],[14,0,\"template-item-title\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"template-item-title-text\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n      \"],[8,[39,0],[[24,0,\"templates-apply\"]],[[\"@action\",\"@icon\"],[[28,[37,1],[[30,0],\"apply\"],null],\"far-clipboard\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"templates-content\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@rawText\"],[[30,1,[\"content\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@template\"],false,[\"d-button\",\"action\",\"cook-text\"]]",
    "moduleName": "discourse/plugins/discourse-templates/discourse/components/d-templates/item.hbs",
    "isStrictMode": false
  });
  let DTemplatesItem = _exports.default = (_class = class DTemplatesItem extends _component2.default {
    apply() {
      // run parametrized action to insert the template
      this.args.onInsertTemplate?.(this.args.template);
      (0, _ajax.ajax)(`/discourse_templates/${this.args.template.id}/use`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "apply", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "apply"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DTemplatesItem);
});