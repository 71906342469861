define("discourse/plugins/discourse-templates/discourse/templates/connectors/editor-preview/d-templates", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.templatesVisible}}
    <div class="d-editor-preview">
      <DButton
        class="modal-close close btn-flat"
        @action={{action "hide"}}
        @icon="times"
      />
      <DTemplates::FilterableList
        @onInsertTemplate={{this.onInsertTemplate}}
        @onAfterInsertTemplate={{action "hide"}}
      />
    </div>
  {{/if}}
  */
  {
    "id": "stWsIIYG",
    "block": "[[[41,[30,0,[\"templatesVisible\"]],[[[1,\"  \"],[10,0],[14,0,\"d-editor-preview\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"modal-close close btn-flat\"]],[[\"@action\",\"@icon\"],[[28,[37,2],[[30,0],\"hide\"],null],\"times\"]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@onInsertTemplate\",\"@onAfterInsertTemplate\"],[[30,0,[\"onInsertTemplate\"]],[28,[37,2],[[30,0],\"hide\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\",\"d-templates/filterable-list\"]]",
    "moduleName": "discourse/plugins/discourse-templates/discourse/templates/connectors/editor-preview/d-templates.hbs",
    "isStrictMode": false
  });
});