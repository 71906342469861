define("discourse/plugins/discourse-templates/discourse/templates/connectors/topic-above-posts/discourse-templates-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="template-topic-controls">
    <DButton
      @icon={{if this.copyConfirm "check" "copy"}}
      @class={{concat-class
        "btn-default"
        "template-copy"
        (if this.copyConfirm "ok")
      }}
      @action={{this.copy}}
      @label="templates.copy"
    />
    <DButton
      @action={{this.createNewTopic}}
      @label="templates.new_topic"
      @icon="plus"
      @class="template-new-topic"
    />
  </div>
  */
  {
    "id": "nOcaYJPp",
    "block": "[[[10,0],[14,0,\"template-topic-controls\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\",\"@class\",\"@action\",\"@label\"],[[52,[30,0,[\"copyConfirm\"]],\"check\",\"copy\"],[28,[37,2],[\"btn-default\",\"template-copy\",[52,[30,0,[\"copyConfirm\"]],\"ok\"]],null],[30,0,[\"copy\"]],\"templates.copy\"]],null],[1,\"\\n  \"],[8,[39,0],null,[[\"@action\",\"@label\",\"@icon\",\"@class\"],[[30,0,[\"createNewTopic\"]],\"templates.new_topic\",\"plus\",\"template-new-topic\"]],null],[1,\"\\n\"],[13]],[],false,[\"d-button\",\"if\",\"concat-class\"]]",
    "moduleName": "discourse/plugins/discourse-templates/discourse/templates/connectors/topic-above-posts/discourse-templates-button.hbs",
    "isStrictMode": false
  });
});